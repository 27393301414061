<template>
    <div>
        <span class="label | border border-green-500 text-green-500 ml-2 leading-none">
            <template v-if="vehicleIsDelivered">{{ $t('clientCard.delivered') }}</template>
            <template v-else-if="vehicleIsRefinanced">{{ $t('clientCard.refinanced') }}</template>
            <template v-else-if="vehicleIsRenewed">{{ $t('clientCard.renewal') }}</template>
            <template v-else-if="vehicleIsSold">{{ soldLabel }}</template>
        </span>
        <span v-if="shouldNotDisplayEmpty && displayEmpty">-</span>
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import Lead from '../../entities/Lead.js';
    import LeadType from '../../entities/LeadType.js';
    import Division from '../../entities/Division.js';
    import Supplier from '../../entities/Supplier.js';
    import { useContextStore } from '../../store/modules/context/store.js';

    export default {
        props: {
            lead: {
                type: Lead,
                required: true,
            },

            displayEmpty: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            soldLabel() {
                return this.contextAccount.hasSupplier(Supplier.POLESTAR) ? this.$tc('clientCard.order') : this.$t('clientCard.sold');
            },

            shouldNotDisplayEmpty() {
                return (
                    !this.vehicleIsSold &&
                    !this.vehicleIsRenewed &&
                    !this.vehicleIsRefinanced &&
                    !this.vehicleIsDelivered
                );
            },

            vehicle() {
                if (this.lead.trashed_wanted_vehicles && this.lead.trashed_wanted_vehicles.length) {
                    return this.getSoldOrFirstTrashedWantedVehicle();
                }

                if (this.isService || this.lead.isOfType(LeadType.RENEWAL)) {
                    return this.lead.exchange_vehicles[0] || {};
                }

                return this.lead.getSoldOrFirstWantedVehicle() || {};
            },

            isService() {
                return this.lead.division_id == Division.SERVICE;
            },

            vehicleIsSold() {
                return this.vehicle.sold && !!this.lead.sale_date;
            },

            vehicleIsRenewed() {
                return this.lead.isOfType(LeadType.RENEWAL) && this.lead.sale && this.lead.sale_date;
            },

            vehicleIsRefinanced() {
                return this.lead.isOfType(LeadType.RENEWAL) && this.lead.refinanced && !!this.lead.refinanced_date;
            },

            vehicleIsDelivered() {
                return this.lead.delivered && !!this.lead.delivered_date;
            },
        },

        methods: {
            getSoldOrFirstTrashedWantedVehicle() {
                const soldVehicle = this.lead.trashed_wanted_vehicles.find(v => v.sold);

                if (!soldVehicle) {
                    return this.lead.trashed_wanted_vehicles[0];
                }

                return soldVehicle;
            },
        },
    };
</script>
