<template>
    <div class="flex flex-col">
        <warning
            icon="regular/cloud-upload"
            :title="$t('error.warning')"
            :content="$t('error.selectAccountWarning')"
            v-if="shouldHideTrashList"
        />
        <template v-else>
            <portal to="content-header-right">
                <activix-tooltip
                    placement="left"
                    :content="showAvailableOnly ? $t('trash.showAll') : $t('trash.showAvailableOnly')"
                >
                    <activix-switcher class="mt-1" v-model="showAvailableOnly" />
                </activix-tooltip>
            </portal>

            <activix-alert class="mb-6" type="warning" v-if="leads.length >= leadsLimit">
                {{ $t('trash.limitAlert', { limit: leadsLimit }) }}
            </activix-alert>

            <div class="box" :class="{ loading: $wait.is('fetching.trashedLeads') }">
                <div class="box-header | h-auto p-6 flex justify-between items-center">
                    <h4 class="box-title | flex items-baseline">
                        {{ $t('search.results') }}
                        <activix-reload :loading="$wait.is('fetching.trashedLeads')" @click="fetchLeads" />
                    </h4>
                    <input
                        class="form-control | w-auto ml-2"
                        type="text"
                        :placeholder="$t('search.search')"
                        v-model="tableState.searchToken"
                    />
                </div>
                <div class="box-body | p-0">
                    <el-table
                        class="w-full border-t-2 border-b overflow-visible"
                        :border="true"
                        :data="paginatedLeads"
                        :default-sort="{ prop: 'deletedAt', order: 'descending' }"
                        :stripe="true"
                        :row-class-name="tableRowClassName"
                        @sort-change="updateTableSorting"
                    >
                        <el-table-column
                            prop="fullname"
                            header-align="center"
                            :label="$t('clientCard.name')"
                            :sortable="true"
                            min-width="150"
                        />
                        <el-table-column
                            align="center"
                            prop="deletedAt"
                            :label="$t('clientCard.deletedAt')"
                            :sortable="true"
                            :formatter="handleTableDateTimeFormat"
                            min-width="140"
                        />
                        <el-table-column
                            align="center"
                            prop="deletedByFullName"
                            :label="$t('clientCard.deletedBy')"
                            :sortable="true"
                            min-width="130"
                        />
                        <el-table-column
                            align="center"
                            min-width="80"
                            :sortable="false"
                            :label="$t('clientCard.restore')"
                        >
                            <template slot-scope="{ row: lead }">
                                <button class="link-grey" :disabled="!canRestore(lead)" @click="restore(lead)">
                                    <activix-tooltip :content="restoreColumnTooltip(lead)">
                                        <icon class="text-xl" name="bold/time-clock-file-sync" />
                                    </activix-tooltip>
                                </button>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="status"
                            header-align="center"
                            align="center"
                            :label="$t('clientCard.status')"
                            :sortable="true"
                            min-width="100"
                        >
                            <template slot-scope="{ row: lead }">
                                <lead-status :lead="lead" :disabled="true" />
                            </template>
                        </el-table-column>
                        <el-table-column
                            align="center"
                            prop="progress"
                            :label="$t('clientCard.progress')"
                            :sortable="true"
                        >
                            <template slot-scope="{ row: lead }">
                                <process-progress class="p-1" :lead="lead" :display-empty="true" />
                            </template>
                        </el-table-column>
                        <el-table-column
                            align="center"
                            prop="leadPhones"
                            width="115"
                            :label="$t('clientCard.phone')"
                            :sortable="true"
                        >
                            <template slot-scope="{ row: lead }">
                                <template v-if="lead.filteredPhones.length">
                                    <div :key="phone.id" v-for="phone in lead.filteredPhones">
                                        {{ formatPhone(phone.phone) }}
                                    </div>
                                </template>
                                <template v-else>
                                    -
                                </template>
                            </template>
                        </el-table-column>
                        <el-table-column
                            align="center"
                            prop="leadEmails"
                            min-width="200"
                            :label="$t('clientCard.email')"
                            :sortable="true"
                        >
                            <template slot-scope="{ row: lead }">
                                <template v-if="lead.filteredEmails.length">
                                    <div :key="email.id" v-for="email in lead.filteredEmails">
                                        {{ email.email }}
                                    </div>
                                </template>
                                <template v-else>
                                    -
                                </template>
                            </template>
                        </el-table-column>
                        <el-table-column
                            align="center"
                            prop="advisorName"
                            min-width="130"
                            :label="$t('general.advisor')"
                            :sortable="true"
                        />
                        <el-table-column
                            align="center"
                            prop="divisionName"
                            :label="$t('general.division')"
                            :sortable="true"
                        />
                        <el-table-column
                            align="center"
                            prop="mainDate"
                            min-width="140"
                            :label="$t('clientCard.createdAt')"
                            :sortable="true"
                            :formatter="handleTableDateTimeFormat"
                        />
                        <el-table-column
                            align="center"
                            prop="wantedVehicle"
                            :label="$t('clientCard.vehicle')"
                            :sortable="true"
                        />
                        <el-table-column
                            align="center"
                            prop="wantedStock"
                            width="110"
                            :label="$t('general.stock')"
                            :sortable="true"
                        />
                        <el-table-column
                            align="center"
                            prop="wantedVin"
                            width="110"
                            :label="$t('clientCard.vehicles.vin')"
                            :sortable="true"
                        />
                        <el-table-column
                            align="center"
                            prop="typeName"
                            :label="$t('accounts.edit.accountType')"
                            :sortable="true"
                            width="120"
                        />
                        <el-table-column
                            align="center"
                            prop="accountName"
                            width="120"
                            :label="$t('general.account')"
                            :sortable="true"
                        />
                        <el-table-column
                            align="center"
                            prop="sale"
                            :label="$t('clientCard.sold')"
                            :sortable="true"
                        >
                            <template slot-scope="{ row: lead }">
                                {{ lead.sale || lead.sale_by_phone ? $t('general.yes') : $t('general.no') }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            align="center"
                            prop="delivered"
                            :label="$t('date.delivered')"
                            :sortable="true"
                        >
                            <template slot-scope="{ row: lead }">
                                {{ lead.delivered ? $t('general.yes') : $t('general.no') }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            align="center"
                            prop="wantedOffer"
                            :label="$t('general.offer')"
                            :sortable="true"
                        />
                        <el-table-column
                            align="center"
                            prop="wantedOrder"
                            min-width="110"
                            :label="$t('accounts.edit.orderNumber')"
                            :sortable="true"
                        />
                        <el-table-column
                            align="center"
                            prop="sourceName"
                            class-name="truncate"
                            min-width="110"
                            :label="$t('clientCard.origin')"
                            :sortable="true"
                        />
                    </el-table>
                    <el-pagination
                        class="p-6"
                        layout="total, sizes, ->, jumper, prev, pager, next"
                        :background="true"
                        :current-page.sync="tableState.currentPage"
                        :page-size.sync="tableState.perPage"
                        :page-sizes="tableState.pageOptions"
                        :pager-count="5"
                        :total="filteredSortedLeads.length"
                        @size-change="updateTablePerPage"
                    />
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import { camelCase, get, orderBy, startCase } from 'lodash-es';
    import StickyScroll from '@/plugins/sticky-scroll.js';

    import { mapState } from 'pinia';
    import { formater } from '../globals/LeadFormatter.js';
    import { formatPhone } from '../utils/index.js';
    import TrackView from '../mixins/TrackView.js';
    import DataTableMixin from '../mixins/DataTable.js';
    import Lead from '../entities/Lead.js';

    import Warning from '../components/Warning.vue';
    import LeadStatus from '../components/dashboards/columns/LeadStatus.vue';
    import ProcessProgress from '../components/lead/ProcessProgress.vue';
    import ActivixDate from '../value-objects/ActivixDate.js';
    import { useContextStore } from '../store/modules/context/store.js';
    import { useGlobalStore } from '../store/store.js';

    export default {
        components: {
            ProcessProgress,
            LeadStatus,
            Warning,
        },

        mixins: [TrackView, DataTableMixin],

        data() {
            return {
                stickyScroll: null,
                showAvailableOnly: true,
                leads: [],
                leadsLimit: 500,
                tableState: {
                    searchToken: '',
                    currentPage: 1,
                    perPage: 10,
                    sorting: {
                        column: 'deletedAt',
                        order: 'desc',
                    },
                },
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser']),
            ...mapState(useContextStore, {
                contextChildAccount: 'contextChildAccount',
                contextAccount: 'account',
                contextGroup: 'group',
            }),

            shouldHideTrashList() {
                return !get(this.contextChildAccount, 'id') || !!get(this.contextGroup, 'id');
            },

            formatedLeads() {
                return this.leads
                    .filter(filteredLead => {
                        return (this.showAvailableOnly && !filteredLead.invalid_supplier) || !this.showAvailableOnly;
                    })
                    .map(tmpLead => {
                        const lead = new Lead(tmpLead);
                        const sourceName = formater.formatSource(lead);
                        const vehicle = this.getVehicle(lead);

                        lead.fullname = startCase(formater.formatClientName(lead));
                        lead.advisorName = this.getFullName(lead.user, false) || '-';
                        lead.divisionName = lead.division ? this.$t(`divisions.${lead.division.name}`) : '-';
                        lead.wantedVehicle =
                            vehicle && (vehicle.make || vehicle.model || vehicle.year)
                                ? `${vehicle.make || ''} ${vehicle.model || ''} ${vehicle.year || ''}`
                                : '-';
                        lead.wantedStock = vehicle ? `${vehicle.stock || '-'}` : '-';
                        lead.wantedVin = vehicle ? `${vehicle.vin || '-'}` : '-';
                        lead.wantedOffer = vehicle ? `${vehicle.offer || '-'}` : '-';
                        lead.wantedOrder = vehicle ? `${vehicle.order_number || '-'}` : '-';
                        lead.typeName = this.$t(`leadTypes.${camelCase(this.getLeadType(lead.lead_type_id))}`);
                        lead.accountName = formater.formatAccountName(lead);
                        lead.sourceName = sourceName != 'none' ? sourceName : '-';
                        lead.deletedAt = lead.deleted_at || '-';
                        lead.mainDate = lead.created_at;
                        lead.filteredEmails = this.filterEmail(lead);
                        lead.filteredPhones = this.filterPhone(lead);
                        lead.deletedByFullName = lead.deleted_by
                            ? `${lead.deleted_by.first_name} ${lead.deleted_by.last_name}`
                            : '-';

                        return lead;
                    });
            },

            filteredSortedLeads() {
                const filteredData = this.searchData(this.tableState.searchToken, this.formatedLeads);
                const sortedData = orderBy(
                    filteredData,
                    [this.tableState.sorting.column],
                    [this.tableState.sorting.order],
                );

                return sortedData;
            },

            paginatedLeads() {
                return this.filteredSortedLeads.slice(
                    (this.tableState.currentPage - 1) * this.tableState.perPage,
                    this.tableState.currentPage * this.tableState.perPage,
                );
            },
        },

        watch: {
            'contextAccount.id': {
                immediate: true,
                handler() {
                    this.fetchLeads();
                },
            },
        },

        methods: {
            filterEmail(lead) {
                const leadDeletedDate = new ActivixDate(lead.deleted_at);

                return lead.trashed_lead_emails.filter(email => {
                    const emailDeletedDate = new ActivixDate(email.deleted_at);
                    return emailDeletedDate.isSameDay(leadDeletedDate);
                });
            },

            filterPhone(lead) {
                const leadDeletedDate = new ActivixDate(lead.deleted_at);

                return lead.trashed_lead_phones.filter(phone => {
                    const phoneDeletedDate = new ActivixDate(phone.deleted_at);
                    return phoneDeletedDate.isSameDay(leadDeletedDate);
                });
            },

            getVehicle(lead) {
                const leadDeletedDate = new ActivixDate(lead.deleted_at);

                const vehicles = lead.trashed_wanted_vehicles.filter(phone => {
                    const phoneDeletedDate = new ActivixDate(phone.deleted_at);
                    return phoneDeletedDate.isSameDay(leadDeletedDate);
                });

                const vehicle = vehicles.find(v => v.sold);

                if (!vehicle) {
                    return vehicles[0];
                }

                return vehicle;
            },

            restoreColumnTooltip(lead) {
                if (this.canRestore(lead)) {
                    return this.$t('general.restore');
                }

                if (lead.invalid_supplier) {
                    const supplierName = this.$t(`suppliers.${lead.invalid_supplier}`);
                    return this.$t('client.restore.cannotRestoreSupplierLead', [supplierName]);
                }

                return this.$t('client.restore.needDeletePermission');
            },

            canRestore(lead) {
                return (
                    !this.disabledRow(lead) && (this.authUser.isAdmin() || this.authUser.custom_permissions.delete_lead)
                );
            },

            tableRowClassName({ row }) {
                if (this.disabledRow(row)) {
                    return 'opacity-50';
                }

                return '';
            },

            disabledRow(lead) {
                return lead.invalid_supplier;
            },

            async restore(lead) {
                if (!lead.id || !this.canRestore(lead)) {
                    return;
                }

                try {
                    const restoredLead = new Lead(await this.$api.leadTrash.restore(lead.id));

                    this.$router.push({
                        name: 'leads.update',
                        params: {
                            id: restoredLead.id,
                        },
                    });
                } catch (error) {
                    this.$notify.error(this.$t('client.restore.restoreFailed'));
                }
            },

            async fetchLeads() {
                if (this.shouldHideTrashList) {
                    return;
                }

                this.$wait.start('fetching.trashedLeads');

                try {
                    this.leads = await this.$api.leadTrash.index(this.contextChildAccount.id);
                    this.$wait.end('fetching.trashedLeads');
                } catch (error) {
                    this.$notify.error(this.$t('client.leadTrashFetchFailed'));
                    this.$wait.end('fetching.trashedLeads');
                    throw error;
                }
            },

            formatPhone(phone) {
                return formatPhone(phone);
            },

            initScroll() {
                this.stickyScroll = new StickyScroll({
                    element: this.body,
                });
            },

            destroyScroll() {
                if (this.stickyScroll) {
                    this.stickyScroll.destroy();
                    this.stickyScroll = null;
                }
            },
        },

        created() {
            this.fetchLeads();
        },

        async mounted() {
            await this.$nextTick();

            this.body = this.$el.querySelector('.el-table__body-wrapper');

            this.initScroll();
        },

        beforeDestroy() {
            this.destroyScroll();
        },
    };
</script>
